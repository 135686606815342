.card-content {
  position: relative;
  height: 3.6em; /* Adjust as needed */
  overflow: hidden;
  line-height: 1.2em;
}

.card-content:after {
  content: "...";
  position: absolute;
  bottom: 0;
  right: 0;
  padding-left: 40px;
  background: linear-gradient(to right, transparent, white 50%);
  pointer-events: none;
}